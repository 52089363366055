exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-slugs-tsx": () => import("./../../../src/pages/slugs.tsx" /* webpackChunkName: "component---src-pages-slugs-tsx" */),
  "component---src-templates-post-tsx-content-file-path-posts-博客迭代日志-【创始文】-tubers-blog-post-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/opt/build/repo/posts/博客迭代日志/【创始文】Tuber's blog/post.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-博客迭代日志-【创始文】-tubers-blog-post-mdx" */),
  "component---src-templates-post-tsx-content-file-path-posts-博客迭代日志-【新功能】首页推荐、更新标记、未读提示-post-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/opt/build/repo/posts/博客迭代日志/【新功能】首页推荐、更新标记、未读提示/post.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-博客迭代日志-【新功能】首页推荐、更新标记、未读提示-post-mdx" */),
  "component---src-templates-post-tsx-content-file-path-posts-博客迭代日志-博客技术架构深度解析（上）：-astro-与-gatsby-post-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/opt/build/repo/posts/博客迭代日志/博客技术架构深度解析（上）：Astro 与 Gatsby/post.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-博客迭代日志-博客技术架构深度解析（上）：-astro-与-gatsby-post-mdx" */),
  "component---src-templates-post-tsx-content-file-path-posts-博客迭代日志-博客技术架构深度解析（下）：-algolia-search、样式系统与状态管理-post-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/opt/build/repo/posts/博客迭代日志/博客技术架构深度解析（下）：Algolia Search、样式系统与状态管理/post.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-博客迭代日志-博客技术架构深度解析（下）：-algolia-search、样式系统与状态管理-post-mdx" */),
  "component---src-templates-post-tsx-content-file-path-posts-博客迭代日志-博客技术架构深度解析（中）：-graph-ql-与数据层-post-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/opt/build/repo/posts/博客迭代日志/博客技术架构深度解析（中）：GraphQL 与数据层/post.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-博客迭代日志-博客技术架构深度解析（中）：-graph-ql-与数据层-post-mdx" */),
  "component---src-templates-post-tsx-content-file-path-posts-博客迭代日志-博客设计理念-post-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/opt/build/repo/posts/博客迭代日志/博客设计理念/post.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-博客迭代日志-博客设计理念-post-mdx" */),
  "component---src-templates-post-tsx-content-file-path-posts-跟着土拨鼠学-golang-微服务-序言-post-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/opt/build/repo/posts/跟着土拨鼠学 Golang 微服务/序言/post.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-跟着土拨鼠学-golang-微服务-序言-post-mdx" */),
  "component---src-templates-slug-tsx-content-file-path-posts-使用-re-script-和-remix-搭建博客-slug-mdx": () => import("./../../../src/templates/Slug.tsx?__contentFilePath=/opt/build/repo/posts/使用 ReScript 和 Remix 搭建博客/slug.mdx" /* webpackChunkName: "component---src-templates-slug-tsx-content-file-path-posts-使用-re-script-和-remix-搭建博客-slug-mdx" */),
  "component---src-templates-slug-tsx-content-file-path-posts-博客迭代日志-slug-mdx": () => import("./../../../src/templates/Slug.tsx?__contentFilePath=/opt/build/repo/posts/博客迭代日志/slug.mdx" /* webpackChunkName: "component---src-templates-slug-tsx-content-file-path-posts-博客迭代日志-slug-mdx" */),
  "component---src-templates-slug-tsx-content-file-path-posts-后端、数据库、服务器基础知识-slug-mdx": () => import("./../../../src/templates/Slug.tsx?__contentFilePath=/opt/build/repo/posts/后端、数据库、服务器基础知识/slug.mdx" /* webpackChunkName: "component---src-templates-slug-tsx-content-file-path-posts-后端、数据库、服务器基础知识-slug-mdx" */),
  "component---src-templates-slug-tsx-content-file-path-posts-计算机组成与结构基础知识-slug-mdx": () => import("./../../../src/templates/Slug.tsx?__contentFilePath=/opt/build/repo/posts/计算机组成与结构基础知识/slug.mdx" /* webpackChunkName: "component---src-templates-slug-tsx-content-file-path-posts-计算机组成与结构基础知识-slug-mdx" */),
  "component---src-templates-slug-tsx-content-file-path-posts-跟着土拨鼠学-golang-微服务-slug-mdx": () => import("./../../../src/templates/Slug.tsx?__contentFilePath=/opt/build/repo/posts/跟着土拨鼠学 Golang 微服务/slug.mdx" /* webpackChunkName: "component---src-templates-slug-tsx-content-file-path-posts-跟着土拨鼠学-golang-微服务-slug-mdx" */),
  "component---src-templates-slug-tsx-content-file-path-posts-通过-solid-js-制作扫雷游戏-slug-mdx": () => import("./../../../src/templates/Slug.tsx?__contentFilePath=/opt/build/repo/posts/通过 Solid.js 制作扫雷游戏/slug.mdx" /* webpackChunkName: "component---src-templates-slug-tsx-content-file-path-posts-通过-solid-js-制作扫雷游戏-slug-mdx" */),
  "component---src-templates-slug-tsx-content-file-path-posts-鸿蒙-harmony-os-next-开发教程-slug-mdx": () => import("./../../../src/templates/Slug.tsx?__contentFilePath=/opt/build/repo/posts/鸿蒙 HarmonyOS NEXT 开发教程/slug.mdx" /* webpackChunkName: "component---src-templates-slug-tsx-content-file-path-posts-鸿蒙-harmony-os-next-开发教程-slug-mdx" */)
}

