import { create } from "zustand";

enum Breakpoint {
  xs = 480,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1600,
}

export const useBreakpoint = create<{
  fullSideBar: boolean;
  collapsedLeft: boolean;
  collapsedRight: boolean;
  setCollapsedLeft: (collapsedLeft: boolean) => void;
}>()((set) => {
  const browser = typeof window !== "undefined" ? window : undefined;

  browser?.addEventListener("resize", () => {
    set({
      fullSideBar: browser?.innerWidth < Breakpoint.xl,
      collapsedLeft: browser?.innerWidth < Breakpoint.xl,
      collapsedRight: browser?.innerWidth < Breakpoint.xxl,
    });
  });

  return {
    fullSideBar: browser?.innerWidth < Breakpoint.xl,
    collapsedLeft: browser?.innerWidth < Breakpoint.xl,
    collapsedRight: browser?.innerWidth < Breakpoint.xxl,
    setCollapsedLeft: (collapsedLeft) => set({ collapsedLeft }),
  };
});
