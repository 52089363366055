import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import {
  Configure,
  Highlight,
  PoweredBy,
  Snippet,
  useHits,
  useInfiniteHits,
  useMenu,
  usePagination,
  useSearchBox,
  useStats,
} from "react-instantsearch";
import {
  Breadcrumb,
  Button,
  Divider,
  Flex,
  Input,
  Layout,
  List,
  Pagination,
  Select,
  Skeleton,
  Space,
  Typography,
} from "antd";
import {
  CloseOutlined,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Icon } from "@iconify/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { searchClient, slugIndex, useSearch } from "../stores/search";
import { useDarkMode } from "../stores/darkMode";
import { useBreakpoint } from "../stores/breakpoint";

const hitsPerPage = 8;

export const SearchInput: React.FC = () => {
  const { refine } = useSearchBox();
  const setSearchIsOpen = useSearch((state) => state.setSearchIsOpen);
  const fullSideBar = useBreakpoint((state) => state.fullSideBar);

  return (
    <Flex
      gap="small"
      align="center"
      css={css`
        height: 100%;
      `}
    >
      <Input
        allowClear
        prefix={<SearchOutlined />}
        placeholder="请输入想要搜索的内容"
        size={fullSideBar ? "large" : null}
        variant={fullSideBar ? "borderless" : "outlined"}
        onChange={(event) => refine(event.target.value)}
      />
      <Button
        type="text"
        icon={<CloseOutlined />}
        size={fullSideBar ? "large" : null}
        onClick={() => setSearchIsOpen(false)}
      />
    </Flex>
  );
};

const SearchFilter: React.FC<{ icons: Map<any, string> }> = ({ icons }) => {
  const { nbHits } = useStats();
  const { items, refine } = useMenu({
    attribute: "slug",
    limit: 100,
  });

  return (
    <Flex align="center" justify="space-between">
      <Typography.Text>{nbHits} results</Typography.Text>
      <Select
        options={[
          {
            label: "全部",
            value: "",
          },
        ].concat(items)}
        defaultValue=""
        variant="borderless"
        popupMatchSelectWidth={false}
        onChange={(value) => refine(value)}
        labelRender={({ label, value }) => (
          <Space>
            {value ? <Icon icon={icons.get(value)} /> : <FilterOutlined />}
            {label}
          </Space>
        )}
        optionRender={({ label, value }) => (
          <Space>
            {value ? <Icon icon={icons.get(value)} /> : <FilterOutlined />}
            {label}
          </Space>
        )}
      />
    </Flex>
  );
};

const SearchList: React.FC<{ hits: any[]; icons: Map<string, string> }> = ({
  hits,
  icons,
}) => (
  <List
    dataSource={hits}
    renderItem={(item) => (
      <List.Item>
        <List.Item.Meta
          avatar={<Icon icon={icons.get(item.slug)} width="2.5rem" />}
          title={
            <Breadcrumb
              items={[
                {
                  title: <Highlight attribute="slug" hit={item} />,
                  href: `/slugs/${item.slug}`,
                },
                {
                  title: <Highlight attribute="title" hit={item} />,
                  href: `/slugs/${item.slug}/${item.title}`,
                },
              ]}
            />
          }
          description={<Snippet attribute="excerpt" hit={item} />}
        />
      </List.Item>
    )}
  />
);

export const SearchContent: React.FC = () => {
  const { hits } = useHits();
  const { hits: infiniteHits, isLastPage, showMore } = useInfiniteHits();
  const fullSideBar = useBreakpoint((state) => state.fullSideBar);
  const [icons, setIcons] = useState(new Map());

  useEffect(() => {
    searchClient
      .searchSingleIndex({
        indexName: slugIndex,
        searchParams: { query: "", hitsPerPage: 100 },
      })
      .then(({ hits }) =>
        setIcons(
          new Map(
            hits.map(({ slug, frontmatter: { icon } }: any) => [slug, icon]),
          ),
        ),
      );
  }, []);

  return (
    <Layout.Content
      css={css`
        padding: 1rem;
      `}
    >
      <SearchFilter icons={icons} />
      {fullSideBar ? (
        <InfiniteScroll
          dataLength={infiniteHits.length}
          next={showMore}
          hasMore={!isLastPage}
          loader={<Skeleton avatar active />}
          endMessage={
            <Divider plain>
              <Typography.Text type="secondary">
                It is all, nothing more 🤐
              </Typography.Text>
            </Divider>
          }
        >
          <SearchList hits={infiniteHits} icons={icons} />
        </InfiniteScroll>
      ) : (
        <>
          <Configure hitsPerPage={hitsPerPage} />
          <SearchList hits={hits} icons={icons} />
        </>
      )}
    </Layout.Content>
  );
};

export const SearchFooter: React.FC = () => {
  const { currentRefinement, nbHits, refine } = usePagination();
  const isDarkMode = useDarkMode((state) => state.isDarkMode);

  return (
    <Flex align="end" justify="space-between">
      <Pagination
        total={nbHits}
        pageSize={hitsPerPage}
        showSizeChanger={false}
        current={currentRefinement + 1}
        onChange={(page) => refine(page - 1)}
      />
      <PoweredBy
        theme={isDarkMode ? "dark" : "light"}
        css={css`
          width: 150px;
        `}
      />
    </Flex>
  );
};
