import React from "react";
import { InstantSearch } from "react-instantsearch";
import { App, ConfigProvider, FloatButton, Layout, theme } from "antd";
import { Header } from "./src/components/Header";
import { SearchContent } from "./src/components/Search";
import { postIndex, searchClient, useSearch } from "./src/stores/search";
import { useDarkMode } from "./src/stores/darkMode";
import { useBreakpoint } from "./src/stores/breakpoint";
import "./global.css";

export const wrapPageElement = ({ element }) => {
  const searchIsOpen = useSearch((state) => state.searchIsOpen);
  const isDarkMode = useDarkMode((state) => state.isDarkMode);
  const fullSideBar = useBreakpoint((state) => state.fullSideBar);

  return (
    <ConfigProvider
      theme={{
        algorithm: [isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm],
        token: {
          fontSizeHeading1: 32,
          fontSizeHeading2: 24,
          fontSizeHeading3: 18,
          fontSizeHeading4: 16,
          fontSizeHeading5: 14,
        },
        components: {
          Layout: {
            headerBg: isDarkMode ? "#141414" : "white",
            headerPadding: fullSideBar ? "0 10px" : "0 50px",
            siderBg: null,
          },
        },
      }}
    >
      <InstantSearch searchClient={searchClient} indexName={postIndex}>
        <App>
          <Layout>
            <Header />
            {searchIsOpen && fullSideBar ? <SearchContent /> : element}
            {fullSideBar && <FloatButton.BackTop />}
          </Layout>
        </App>
      </InstantSearch>
    </ConfigProvider>
  );
};
