import { navigate } from "gatsby";
import React from "react";
import { css } from "@emotion/react";
import { Button, Dropdown, Flex, Menu, Space } from "antd";
import {
  DiscordOutlined,
  EllipsisOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoonOutlined,
  SearchOutlined,
  SunOutlined,
} from "@ant-design/icons";
import { useSearch } from "../stores/search";
import { useDarkMode } from "../stores/darkMode";
import { useBreakpoint } from "../stores/breakpoint";

function headerFuncItems() {
  const setSearchIsOpen = useSearch((state) => state.setSearchIsOpen);
  const isDarkMode = useDarkMode((state) => state.isDarkMode);
  const setDarkMode = useDarkMode((state) => state.setDarkMode);

  return [
    {
      key: "Search",
      label: "Search",
      icon: <SearchOutlined />,
      onClick: () => setSearchIsOpen(true),
    },
    {
      key: "Discord",
      label: "Discord",
      icon: <DiscordOutlined />,
      onClick: () => navigate("https://t.ly/gzxeh"),
    },
    {
      key: "Theme",
      label: isDarkMode ? "Light" : "Dark",
      icon: isDarkMode ? <SunOutlined /> : <MoonOutlined />,
      onClick: () => setDarkMode(!isDarkMode),
    },
  ];
}

export const Navigation: React.FC = () => {
  const fullSideBar = useBreakpoint((state) => state.fullSideBar);
  const collapsedLeft = useBreakpoint((state) => state.collapsedLeft);
  const setCollapsedLeft = useBreakpoint((state) => state.setCollapsedLeft);
  const collapsedRight = useBreakpoint((state) => state.collapsedRight);

  return (
    <Flex align="center" justify="space-between">
      {fullSideBar ? (
        <Button
          type="text"
          size="large"
          icon={collapsedLeft ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsedLeft(!collapsedLeft)}
        />
      ) : (
        <span
          css={css`
            font-family: "Poetsen One", sans-serif;
            font-size: x-large;
          `}
        >
          Tuber's Blog
        </span>
      )}
      <Menu
        mode="horizontal"
        items={[
          {
            key: "",
            label: "首页",
          },
          {
            key: "slugs",
            label: "合集",
          },
        ]}
        selectedKeys={[location.pathname.split("/")[1]]}
        onClick={({ key }) => navigate("/" + key)}
        css={css`
          min-width: 122px;
        `}
      />
      {collapsedRight ? (
        <Dropdown menu={{ items: headerFuncItems() }}>
          <Button type="text" size="large" icon={<EllipsisOutlined />} />
        </Dropdown>
      ) : (
        <Space>
          {headerFuncItems().map((item) => (
            <Button type="text" size="large" {...item} />
          ))}
        </Space>
      )}
    </Flex>
  );
};
