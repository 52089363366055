import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useDarkMode = create<{
  isDarkMode: boolean;
  setDarkMode: (isDarkMode: boolean) => void;
}>()(
  persist(
    (set) => {
      const browser = typeof window !== "undefined" ? window : undefined;

      let darkMode = browser?.matchMedia("(prefers-color-scheme: dark)");

      darkMode?.addEventListener("change", (event) =>
        set({ isDarkMode: event.matches }),
      );

      return {
        isDarkMode: darkMode?.matches,
        setDarkMode: (isDarkMode) => set({ isDarkMode }),
      };
    },
    { name: "isDarkMode" },
  ),
);
