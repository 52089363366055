import React, { useEffect } from "react";
import { css } from "@emotion/react";
import { App, Layout, Modal } from "antd";
import { Navigation } from "./Navigation";
import { SearchContent, SearchFooter, SearchInput } from "./Search";
import { useSearch } from "../stores/search";
import { useDarkMode } from "../stores/darkMode";
import { useBreakpoint } from "../stores/breakpoint";

export const Header: React.FC = () => {
  const { modal } = App.useApp();
  const searchIsOpen = useSearch((state) => state.searchIsOpen);
  const setSearchIsOpen = useSearch((state) => state.setSearchIsOpen);
  const isDarkMode = useDarkMode((state) => state.isDarkMode);
  const fullSideBar = useBreakpoint((state) => state.fullSideBar);

  useEffect(() => {
    searchIsOpen && !fullSideBar
      ? modal.confirm({
          width: 800,
          icon: null,
          centered: true,
          maskClosable: true,
          title: <SearchInput />,
          content: <SearchContent />,
          footer: <SearchFooter />,
          afterClose: () => setSearchIsOpen(false),
        })
      : Modal.destroyAll();
  }, [searchIsOpen, fullSideBar]);

  return (
    <Layout.Header
      css={css`
        position: sticky;
        top: 0;
        z-index: 100;
        border-bottom: ${isDarkMode
            ? "rgba(253, 253, 253, 0.12)"
            : "rgba(5, 5, 5, 0.06)"}
          solid 1px;
      `}
    >
      {searchIsOpen && fullSideBar ? <SearchInput /> : <Navigation />}
    </Layout.Header>
  );
};
