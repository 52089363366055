import { algoliasearch } from "algoliasearch";
import { create } from "zustand";

const appId = "ZQOU7LDDYY";
const apiKey = "748033fa42230bffd8351926fb168ca7";

export const searchClient = algoliasearch(appId, apiKey);
export const slugIndex = `BlogSlug`;
export const postIndex = `BlogPost`;

const slugQuery = `{
  allMdx(filter: { internal: { contentFilePath: { regex: "/slug.mdx$/" } } }) {
    nodes {
      id
      internal {
        contentDigest
        contentFilePath
      }
      frontmatter {
        index
        icon
        versions
      }
      excerpt
    }
  }
}`;

const postQuery = `{
  allMdx(filter: { internal: { contentFilePath: { regex: "/post.mdx$/" } } }) {
    nodes {
      id
      internal {
        contentDigest
        contentFilePath
      }
      frontmatter {
        index
        tags
        abstract
      }
      tableOfContents
      excerpt
    }
  }
}`;

export const searchOptions = {
  appId,
  apiKey,
  queries: [
    {
      indexName: slugIndex,
      query: slugQuery,
      transformer: ({ data }) =>
        data.allMdx.nodes.map((node: any) => ({
          slug: node.internal.contentFilePath.split("/").reverse()[1],
          ...node,
        })),
    },
    {
      indexName: postIndex,
      query: postQuery,
      transformer: ({ data }) =>
        data.allMdx.nodes.map((node: any) => {
          const filePath = node.internal.contentFilePath.split("/").reverse();

          return {
            slug: filePath[2],
            title: filePath[1],
            ...node,
          };
        }),
      settings: {
        attributesForFaceting: ["slug"],
        attributesToSnippet: ["excerpt:30"],
      },
    },
  ],
};

export const useSearch = create<{
  searchIsOpen: boolean;
  setSearchIsOpen: (searchIsOpen: boolean) => void;
}>()((set) => ({
  searchIsOpen: false,
  setSearchIsOpen: (searchIsOpen) => set({ searchIsOpen }),
}));
